export const state = () => ({
  markets: [],
  upcomingMarkets: [],
  loading: false,
  error: {}
})

export const mutations = {
  set_markets(state, data) {
    if (data) {
      const matchIds = []

      data.forEach((e) => {
        if (e.markets && e.markets.length > 0) {
          e.markets.forEach((market) => {
            if (
              market.inPlayStatus === 'Open' &&
              market.betfairStatus !== 'SUSPENDED'
            ) {
              matchIds.push(market.betfairId)
            }
          })
        }
      })
      if (matchIds.length > 0) {
        this.commit('socketInplayRate/SET_IDS', matchIds)
        this.commit('socketBookmaker/SET_IDS', matchIds)
        this.commit('socketMain/SET_IDS', matchIds)
      }
    }
    state.markets = data
  },
  set_upcoming_markets(state, data) {
    if (data) {
      const matchIds = []

      data.forEach((e) => {
        if (e.markets && e.markets.length > 0) {
          e.markets.forEach((market) => {
            matchIds.push(market.betfairId)
          })
        }
      })
      if (matchIds.length > 0) {
        this.commit('socketRate/SET_IDS', matchIds)
        this.commit('socketBookmaker/SET_IDS', matchIds)
        this.commit('socketMain/SET_IDS', matchIds)
      }
    }
    state.upcomingMarkets = data
  },
  set_error(state, data) {
    state.error = data
  },
  set_loading(state, data) {
    state.loading = data
  }
}
export const actions = {
  async get(
    { commit },
    { userId, socketData, upComing = false, loading = true }
  ) {
    try {
      // commit('set_loading', loading)
      if (!socketData) {
        const isPublic = !localStorage.getItem('accessToken')

        const { data } = await this.$axios({
          method: 'get',
          baseURL: process.env.BASE_URL,
          url: isPublic
            ? 'public/exchange/odds/inPlayAndPopularEvents'
            : '/exchange/odds/inPlayAndPopularEvents',
          headers: {
            authorization: localStorage.getItem('accessToken')
          }
        }) // console.log(
        //   '!!!!!! SB inplay',
        //   data.result.popularEvents.filter((item) =>
        //     item.market.id.includes('SB')
        //   )
        // )
        commit(
          'set_upcoming_markets',
          data.result.popularEvents.filter(
            (item) => !item.market.id.includes('BR')
          )
        )
        commit(
          'set_markets',
          data.result.inPlayEvents.filter(
            (item) => !item.market.id.includes('BR')
          )
        )
      } else if (socketData && upComing) {
        commit('set_upcoming_markets', socketData)
      } else {
        commit('set_markets', socketData)
      }
      // commit('set_loading', false)
    } catch (error) {
      commit('set_error', error)
      // commit('set_loading', false)
    }
  }
}
