var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"user-balance"},[(_vm.$auth.loggedIn)?_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',[_c('v-list-item-subtitle',[_vm._v("\n        Wallet Amount\n        ")]),_vm._v(" "),_c('v-list-item-title',{staticClass:"font-weight-bold body-1 success--text"},[_vm._v("\n        "+_vm._s(_vm._f("currencyFormat")(_vm.userBalanceData && _vm.userBalanceData.useBWallet
            ? _vm.userBalanceData.bWallet.availBalance
            : _vm.userBalanceData && _vm.userBalanceData.useSWallet
            ? _vm.userBalanceData.sWallet.availBalance
            : _vm.userBalanceData && _vm.userBalanceData.useCWallet
            ? _vm.userBalanceData.cWallet.availBalance
            : _vm.userBalanceData.availBalance,0))+"\n      ")])],1),_vm._v(" "),_c('v-list-item',[_c('v-list-item-subtitle',[_vm._v("Main Wallet Exposure ")]),_vm._v(" "),_c('v-list-item-title',{staticClass:"font-weight-bold body-1 error--text"},[_c('div',[_vm._v("\n          "+_vm._s(_vm._f("currencyFormat")(_vm.userBalanceData &&
            (_vm.userBalanceData.deductedExposure / _vm.getMyRate()),0))+"\n        ")])])],1),_vm._v(" "),_c('v-list-item',[_c('v-list-item-subtitle',[_vm._v(" Main Wallet Balance ")]),_vm._v(" "),_c('v-list-item-title',{staticClass:"font-weight-bold body-1"},[(_vm.userBalanceData.availBalance)?_c('div',[_vm._v("\n          "+_vm._s(_vm._f("currencyFormat")(_vm.userBalanceData.balanceWithoutBonus,0))+"\n        ")]):_vm._e()])],1),_vm._v(" "),_c('v-list-item',[_c('v-list-item-subtitle',[_vm._v(" Free Cash ")]),_vm._v(" "),_c('v-list-item-title',{staticClass:"font-weight-bold body-1"},[(_vm.userBalanceData.availBalance)?_c('div',[_vm._v("\n          "+_vm._s(_vm._f("currencyFormat")(_vm.userBalanceData.bonusAmount,0))+"\n        ")]):_vm._e()])],1),_vm._v(" "),(_vm.userBalanceData.bWallet && _vm.isB2C)?_c('div',[(_vm.userBalanceData.bWallet)?_c('v-list-item',[_vm._v("Bonus Wallet\n        "),_c('v-img',{staticClass:"infoImg",attrs:{"src":require(`assets/images/info.svg`),"max-width":"20","alt":"survey"},on:{"click":function($event){return _vm.redirect()}}})],1):_vm._e(),_vm._v(" "),_c('v-list-item',[_c('v-list-item-subtitle',[_vm._v("Available Credit:")]),_vm._v(" "),_c('v-list-item-title',{staticClass:"font-weight-bold body-1"},[(
              _vm.userBalanceData.bWallet && !_vm.userBalanceData.bWallet.availBalance
            )?_c('div',[_vm._v("\n            0\n          ")]):_c('div',[_vm._v("\n            "+_vm._s(_vm._f("currencyFormat")(_vm.userBalanceData.bWallet &&
              _vm.userBalanceData.bWallet.availBalance,0))+"\n          ")])])],1),_vm._v(" "),_c('v-list-item',[_c('v-list-item-subtitle',[_vm._v("Bonus Wallet Exposure:")]),_vm._v(" "),_c('v-list-item-title',{staticClass:"font-weight-bold body-1 error--text"},[(
              _vm.userBalanceData.bWallet &&
              !_vm.userBalanceData.bWallet.deductedExposure
            )?_c('div',[_vm._v("\n            0\n          ")]):_c('div',[_vm._v("\n            "+_vm._s(_vm._f("currencyFormat")(_vm.userBalanceData.bWallet &&
              _vm.userBalanceData.bWallet.deductedExposure,0))+"\n          ")])])],1),_vm._v(" "),_c('v-list-item',[_c('v-list-item-subtitle',[_vm._v("Use Bonus")]),_vm._v(" "),_c('v-list-item-title',{staticClass:"font-weight-bold body-1"}),_vm._v(" "),_c('v-switch',{staticStyle:{"position":"absolute","left":"50%","bottom":"7px"},attrs:{"loading":_vm.loadingBonusToggle,"color":"green","inset":"","hide-details":""},on:{"change":function($event){return _vm.changeWalletStatus(_vm.useBWallet, 'B')}},model:{value:(_vm.useBWallet),callback:function ($$v) {_vm.useBWallet=$$v},expression:"useBWallet"}})],1)],1):_vm._e(),_vm._v(" "),(_vm.userBalanceData.sWallet && _vm.isB2C)?_c('div',[_c('v-list-item',{staticClass:"bonusTitle"},[_vm._v("Sport Bonus\n        "),_c('v-img',{staticClass:"infoImg",attrs:{"src":require(`assets/images/info.svg`),"max-width":"20","alt":"survey"},on:{"click":function($event){return _vm.redirect()}}})],1),_vm._v(" "),_c('v-list-item',[_c('v-list-item-subtitle',[_vm._v("Available Credit:")]),_vm._v(" "),_c('v-list-item-title',{staticClass:"font-weight-bold body-1"},[(
              _vm.userBalanceData.sWallet && !_vm.userBalanceData.sWallet.availBalance
            )?_c('div',[_vm._v("\n            0\n          ")]):_c('div',[_vm._v("\n            "+_vm._s(_vm._f("currencyFormat")(_vm.userBalanceData.sWallet &&
              _vm.userBalanceData.sWallet.availBalance,0))+"\n          ")])])],1),_vm._v(" "),_c('v-list-item',[_c('v-list-item-subtitle',[_vm._v("Sport Wallet Exposure:")]),_vm._v(" "),_c('v-list-item-title',{staticClass:"font-weight-bold body-1 error--text"},[(
              _vm.userBalanceData.sWallet &&
              !_vm.userBalanceData.sWallet.deductedExposure
            )?_c('div',[_vm._v("\n            0\n          ")]):_c('div',[_vm._v("\n            "+_vm._s(_vm._f("currencyFormat")(_vm.userBalanceData.sWallet &&
              _vm.userBalanceData.sWallet.deductedExposure,0))+"\n          ")])])],1),_vm._v(" "),_c('v-list-item',[_c('v-list-item-subtitle',[_vm._v("Use Bonus")]),_vm._v(" "),_c('v-list-item-title',{staticClass:"font-weight-bold body-1"}),_vm._v(" "),_c('v-switch',{staticStyle:{"position":"absolute","left":"50%","bottom":"7px"},attrs:{"loading":_vm.loadingBonusToggle,"color":"green","inset":"","hide-details":""},on:{"change":function($event){return _vm.changeWalletStatus(_vm.useSWallet, 'S')}},model:{value:(_vm.useSWallet),callback:function ($$v) {_vm.useSWallet=$$v},expression:"useSWallet"}})],1)],1):_vm._e(),_vm._v(" "),(_vm.userBalanceData.cWallet && _vm.isB2C)?_c('div',[_c('v-list-item',[_vm._v("Casino Bonus\n        "),_c('v-img',{staticClass:"infoImg",attrs:{"src":require(`assets/images/info.svg`),"max-width":"20","alt":"survey"},on:{"click":function($event){return _vm.redirect()}}})],1),_vm._v(" "),_c('v-list-item',[_c('v-list-item-subtitle',[_vm._v("Available Credit:")]),_vm._v(" "),_c('v-list-item-title',{staticClass:"font-weight-bold body-1"},[(
              _vm.userBalanceData.cWallet && !_vm.userBalanceData.cWallet.availBalance
            )?_c('div',[_vm._v("\n            0\n          ")]):_c('div',[_vm._v("\n            "+_vm._s(_vm._f("currencyFormat")(_vm.userBalanceData.cWallet &&
              _vm.userBalanceData.cWallet.availBalance,0))+"\n          ")])])],1),_vm._v(" "),_c('v-list-item',[_c('v-list-item-subtitle',[_vm._v("Casino Wallet Exposure:")]),_vm._v(" "),_c('v-list-item-title',{staticClass:"font-weight-bold body-1 error--text"},[(
              _vm.userBalanceData.cWallet &&
              !_vm.userBalanceData.cWallet.deductedExposure
            )?_c('div',[_vm._v("\n            0\n          ")]):_c('div',[_vm._v("\n            "+_vm._s(_vm._f("currencyFormat")(_vm.userBalanceData.cWallet &&
              _vm.userBalanceData.cWallet.deductedExposure,0))+"\n          ")])])],1),_vm._v(" "),_c('v-list-item',[_c('v-list-item-subtitle',[_vm._v("Use Bonus")]),_vm._v(" "),_c('v-list-item-title',{staticClass:"font-weight-bold body-1"}),_vm._v(" "),_c('v-switch',{staticStyle:{"position":"absolute","left":"50%","bottom":"7px"},attrs:{"loading":_vm.loadingBonusToggle,"color":"green","inset":""},on:{"change":function($event){return _vm.changeWalletStatus(_vm.useCWallet, 'C')}},model:{value:(_vm.useCWallet),callback:function ($$v) {_vm.useCWallet=$$v},expression:"useCWallet"}})],1)],1):_vm._e()],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"awating-bonus"},[_c('div',{staticClass:"bonus-btn",on:{"click":function($event){return _vm.goToBonus()}}},[_c('span',{staticClass:"bonus-text"},[_vm._v("Awaiting Bonus")]),_vm._v(" "),_c('span',{staticClass:"bonus-amount"},[_vm._v(_vm._s(_vm.getAwaitingBonus || 0))])])]),_vm._v(" "),_c('div',{staticClass:"deposit-withdraw-btn"},[_c('v-btn',{staticClass:"view-benefits-btn f12",attrs:{"to":"/wallet/deposit"}},[_c('span',{staticClass:"v-btn__conten"},[_vm._v(" Deposit ")])]),_vm._v(" "),_c('v-btn',{staticClass:"view-benefits-btn f12",attrs:{"to":"/wallet/withdrawal"}},[_c('span',{staticClass:"v-btn__conten"},[_vm._v(" Withdraw Now ")])])],1),_vm._v(" "),_c('div',{staticClass:"referral-btn col"},[_c('v-btn',{staticClass:"referral---btn f12",attrs:{"block":"","to":"/referral"}},[_c('span',{staticClass:"ref-btn__conten"},[_vm._v(" Refer and earn ")])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }