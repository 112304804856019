var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{class:_vm.$vuetify.theme.dark ? '' : 'light-pattern'},[_c('cache-clear-modal'),_vm._v(" "),_c('share-modal'),_vm._v(" "),_c('fairplay-notification'),_vm._v(" "),_c('notification-marquee'),_vm._v(" "),_c('network-bar'),_vm._v(" "),_c('drawer'),_vm._v(" "),_c('header-bar'),_vm._v(" "),_c('v-main',{staticClass:"main-section",class:{ 'pt-2': _vm.$breakpoint.smAndDown }},[_c('snackbar'),_vm._v(" "),_c('v-lazy',[_c('nuxt')],1)],1),_vm._v(" "),(
      _vm.$route.name === 'index' ||
      _vm.$route.name === 'favorites' ||
      _vm.$route.name === 'inplay' ||
      _vm.$route.name === 'sportsbook-category' ||
      _vm.$route.name === 'sportsbook' ||
      _vm.$route.name === 'sportsbook-category-subCategory-id' ||
      _vm.$route.name === 'sportsbook-category-subCategory-id-betradar' ||
      _vm.$route.name === 'sportsbook-category-id'
    )?_c('oneclick-bet'):_vm._e(),_vm._v(" "),_c('favorites-marquee',{staticClass:"fav-marquee",attrs:{"id":"fav_marquee"}}),_vm._v(" "),_c('place-bet-modal'),_vm._v(" "),(_vm.$route.name.startsWith('sportsbook') && _vm.setUp !== 'fps')?_c('low-balance-modal'):_vm._e(),_vm._v(" "),_c('div',{staticClass:"px-3"},[_c('footer-bar')],1),_vm._v(" "),(_vm.$auth.user && _vm.$auth.user._id)?_c('ipquality',{staticClass:"d-none"}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }