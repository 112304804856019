<template>
  <v-app :class="$vuetify.theme.dark ? '' : 'light-pattern'">
    <cache-clear-modal />
    <!-- <placebet-loader
      v-if="
        $auth.loggedIn &&
        this.$store.state.settings.userConfig &&
        this.$store.state.settings.userConfig.oneClickStatus
      "
      fixed
    ></placebet-loader> -->
    <share-modal></share-modal>
    <fairplay-notification />
    <notification-marquee></notification-marquee>
    <network-bar></network-bar>
    <drawer />
    <header-bar />
    <v-main class="main-section" :class="{ 'pt-2': $breakpoint.smAndDown }">
      <snackbar />
      <v-lazy>
        <nuxt />
      </v-lazy>
    </v-main>

    <oneclick-bet
      v-if="
        $route.name === 'index' ||
        $route.name === 'favorites' ||
        $route.name === 'inplay' ||
        $route.name === 'sportsbook-category' ||
        $route.name === 'sportsbook' ||
        $route.name === 'sportsbook-category-subCategory-id' ||
        $route.name === 'sportsbook-category-subCategory-id-betradar' ||
        $route.name === 'sportsbook-category-id'
      "
    ></oneclick-bet>

    <!-- <bets-ticker v-if="$route.name != 'index'" /> -->
    <favorites-marquee id="fav_marquee" class="fav-marquee"></favorites-marquee>

    <place-bet-modal />
    <low-balance-modal
      v-if="$route.name.startsWith('sportsbook') && setUp !== 'fps'"
    />
    <div class="px-3">
      <footer-bar />
    </div>
    <ipquality v-if="$auth.user && $auth.user._id" class="d-none" />
  </v-app>
</template>

<script>
import NetworkBar from '@/components/layouts/NetworkBar'
import Snackbar from '@/components/layouts/Snackbar'
import Drawer from '@/components/fairplay/layouts/Drawer'
import HeaderBar from '@/components/fairplay/layouts/HeaderBar'
// import BetsTicker from '@/components/layouts/BetsTicker'
// import PlacebetLoader from '@/components/PlacebetLoader'
import FooterBar from '@/components/fairplay/layouts/FooterBar'
import OneclickBet from '@/components/OneclickBet'
import FairplayNotification from '@/components/fairplay/FairplayNotification'
import NotificationMarquee from '@/components/NotificationMarquee'
import FavoritesMarquee from '@/components/favorites/FavoritesMarquee'
import PlaceBetModal from '@/components/fairplay/modals/PlaceBetModal'
import LowBalanceModal from '@/components/fairplay/modals/LowBalanceModal'
import CacheClearModal from '@/components/fairplay/modals/CacheClearModal'
import ShareModal from '@/components/ShareModal'
import Ipquality from '@/components/ipquality'
import eventScript from '@/services/script'
import { indexLink } from '~/constant'
// import ActionButtons from '@/components/fairplay/ActionButtons'

export default {
  components: {
    HeaderBar,
    FooterBar,
    Drawer,
    // mobileDrawer,
    // PlacebetLoader,
    // BetsTicker,
    Snackbar,
    NetworkBar,
    OneclickBet,
    FairplayNotification,
    NotificationMarquee,
    FavoritesMarquee,
    PlaceBetModal,
    LowBalanceModal,
    CacheClearModal,
    ShareModal,
    Ipquality
    // ActionButtons
  },
  middleware: ['favIconCheck'],
  data() {
    return {
      setUp: process.env.setUp
    }
  },
  head() {
    return {
      link: [
        {
          rel: 'canonical',
          href: process.env.BASE_URL + this.$route.path
        }
      ]
    }
  },
  computed: {
    version() {
      return this.$store.state.profile.appversion
    }
  },
  mounted() {
    // this.blockUserIp()
    if (window.location.origin !== indexLink) {
      eventScript.noindexScript()
    }
    if (process.env.fairplayEnvironment === 'PROD') {
      // document.onkeydown = function (e) {
      //   if (e.ctrlKey) {
      //     return false
      //   }
      // }
      document.addEventListener('contextmenu', (event) =>
        event.preventDefault()
      )
    }
  },
  methods: {
    // blockUserIp() {
    //   this.$store.dispatch('ip/blockUserIp')
    // }
  }
}
</script>
<style scoped>
.light-pattern {
  background-image: url('~assets/images/light-pattern.jpg');
  background-repeat: repeat;
  background-size: 55px;
}
/* .action-button {
  display: none;
} */
/* @media (min-width: 1024px) and (max-width: 2560px) {
  .action-button {
    display: block;
  }
}
@media (max-width: 280px) {
  .action-button {
    display: block;
  }
} */
</style>
