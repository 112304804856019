/// <reference path="../components/fairplay/wallets/bonuscard.type.js" />

/**
 *
 * @param {loyaltyData} loyaltyData
 * @returns {string}
 */
export const getLoyalty = (loyaltyData) => {
  let loyalty = ''
  const turnover = Number(loyaltyData.turnover)
  const tier = loyaltyData.tierSystem.tiers.find((tier) => {
    return turnover >= Number(tier.min) && turnover <= Number(tier.max)
  })
  if (tier) {
    loyalty = tier.name
  }
  return loyalty
}

class TrieNode {
  constructor() {
    this.children = {}
    this.isEndOfWord = false
  }
}

export class Trie {
  constructor() {
    this.root = new TrieNode()
  }

  insert(word) {
    let node = this.root
    for (let i = 0; i < word.length; i++) {
      const char = word[i]
      if (!node.children[char]) {
        node.children[char] = new TrieNode()
      }
      node = node.children[char]
    }
    node.isEndOfWord = true
  }

  search(word) {
    let node = this.root
    for (let i = 0; i < word.length; i++) {
      const char = word[i]
      if (!node.children[char]) {
        return false
      }
      node = node.children[char]
    }
    return node.isEndOfWord
  }

  startsWith(prefix) {
    let node = this.root
    for (let i = 0; i < prefix.length; i++) {
      const char = prefix[i]
      if (!node.children[char]) {
        return false
      }
      node = node.children[char]
    }
    return true
  }
}
