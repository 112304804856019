<template>
  <div>
    <v-navigation-drawer
      v-if="$auth.loggedIn"
      v-model="drawer"
      black
      app
      right
      temporary
      class="black"
    >
      <div class="pb-10 left-border">
        <profile-card :is-left="false" title-size="f14"></profile-card>
        <user-balance></user-balance>
        <v-divider class="mt-4"></v-divider>
        <v-list dense class="pt-0">
          <v-list-item
            v-for="item in items"
            v-show="!item.disable || (item.disable && item.disable !== isB2C)"
            :key="item.title"
            :to="
              item?.fpsLink && version?.projectCode === 'fps' ? '' : item.link
            "
            :href="
              item?.fpsLink && version?.projectCode === 'fps'
                ? goToWhatsapp()
                : ''
            "
            target="blank"
            class="py-1 list_border"
          >
            <v-list-item-icon class="mr-4">
              <v-img
                :src="require(`@/assets/images/drawer/${item.img}`)"
                :lazy-src="require(`@/assets/images/drawer/${item.img}`)"
                class="pt-1 right-side-items"
                height="20"
                width="20"
                contain
                :alt="item.title"
              ></v-img>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold f14">
                {{ $t(item.title) }}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action v-if="item.title === 'Open Bets'">
              <span class="count-badge">{{ totalNoCount }}</span>
            </v-list-item-action>
          </v-list-item>
          <v-list-item class="list_border" @click="openFeedback">
            <v-list-item-icon class="mr-4">
              <v-img
                :src="require('@/assets/images/drawer/feedback.png')"
                class="pt-1"
                height="20"
                width="20"
                contain
                :alt="'Feedback'"
              ></v-img>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold f14"
                >Feedback</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <div class="pt-0 grey darken-3">
          <div class="v-list-item__content">
            <div
              class="v-list-item__title text-center font-weight-bold white--text my-3"
            >
              Connect with us on
            </div>
          </div>
          <div class="px-2 py-0 pb-6 grey darken-3 whatsapp-style col">
            <button
              type="button"
              class="pa-4 f12 referal_btn whatsapp-text grey darken-3 v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--default"
            >
              <span class="v-btn__content"
                ><div
                  aria-label="Whatsapp Now"
                  role="img"
                  class="v-image v-responsive mr-2 theme--light"
                  style="height: 23px; max-width: 23px; width: 23px"
                >
                  <div
                    class="v-responsive__sizer"
                    style="padding-bottom: 100%"
                  ></div>
                  <div
                    class="v-image__image v-image__image--contain"
                    :style="{
                      backgroundImage: `url(${getWhatsappLogo()})`,
                      backgroundPosition: 'center center'
                    }"
                  ></div>
                  <div class="v-responsive__content" style="width: 45px"></div>
                </div>
                <span class="white--text font-weight-bold">
                  Whatsapp
                </span></span
              >
            </button>
          </div>
        </div>
        <v-divider></v-divider>
        <div class="pb-10 padding-bottom">
          <v-btn
            class="br-0"
            color="#4caf50"
            block
            @click="$store.dispatch('user/logout')"
            >Logout</v-btn
          >
        </div>
      </div>
    </v-navigation-drawer>
    <v-navigation-drawer
      v-model="drawer1"
      :color="$vuetify.theme.dark ? 'black darken-3' : 'grey lighten-2'"
      app
      left
      temporary
      width="250"
    >
      <profile-card :is-left="true" title-size="f14"></profile-card>

      <v-list dense class="pa-0 drawerLeft">
        <v-col
          v-for="item in drawerLeft2"
          v-show="
            !item.disable ||
            (item.disable && item.disable !== version.projectCode)
          "
          :key="item.text"
          cols="12"
          class="px-0 py-0 list___items"
        >
          <v-list-item
            color="primary"
            class="text-center leftheight border-bottom"
            @click="redirectToUrl(item.link)"
          >
            <!-- :target="item.target"
              rel="noreferrer"-->
            <v-list-item-content class="ml-4">
              <div class="d-flex align-items-center justify-start">
                <img
                  :src="require(`@/assets/images/drawer/${item.img}`)"
                  :alt="item.alt"
                  width="20"
                  height="20"
                  class="invert___image"
                />
                <div class="d-flex justify-space-between arrow_width">
                  <span
                    class="text-capitalize subtitle-1 font-weight-bold pl-3 white--text"
                    :class="$vuetify.theme.dark ? 'white--text' : 'black--text'"
                    >{{ item.text }}</span
                  >
                  <v-icon light class="pr-1 icon">mdi-chevron-right</v-icon>
                </div>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-list>
      <v-row no-gutters class="drawerBt">
        <v-col cols="12" class="ml-4">
          <switch-locale v-if="$breakpoint.mdAndDown" class="mt-8">
          </switch-locale>
          <v-btn
            large
            class="primary align-self-center my-3 ml-5"
            @click="redirectToUrl('apk')"
          >
            <span class="font-weight-bold white--text"> Download APP </span>
          </v-btn>
        </v-col>
      </v-row>
    </v-navigation-drawer>

    <!-- session timeout modal -->
    <div>
      <SessionTimeEndModal
        :idle-time="idleTime"
        :show-modal-time="showModalTime"
        @SessionTimeOut="SessionOut($event)"
      >
      </SessionTimeEndModal>
    </div>

    <!-- feedback dialog -->
    <v-dialog
      v-model="feedbackFormDialog"
      scrollable
      max-width="400"
      content-class="feedbackFrom"
    >
      <v-container class="pa-0 ma-0">
        <v-card :class="$breakpoint.xsOnly ? 'pa-4' : 'pa-6'">
          <v-card-title class="text-h5">Feedback</v-card-title>
          <v-form ref="feedbackFrom" v-model="valid" lazy-validation>
            <div class="question-three mb-4">
              <div>
                <v-textarea
                  v-model="feedbackInput"
                  outlined
                  class="rounded-lg"
                  rows="3"
                  :rules="rules.feedbackInput"
                  placeholder="Write a comment or describe a problem."
                ></v-textarea>
              </div>
            </div>
          </v-form>
          <v-card-actions class="pa-4 pt-0">
            <v-btn color="primary" @click="submitUserFeedback"> Submit </v-btn>
            <v-btn color="primary" text @click="Closedialog"> Close </v-btn>
          </v-card-actions>
        </v-card>
      </v-container>
    </v-dialog>
  </div>
</template>

<script>
// import { remove } from 'lodash'
import { mapState } from 'vuex'
import constants from '../../../constant'
import UserBalance from '@/components/fairplay/UserBalance'
import SessionTimeEndModal from '@/components/SessionTimeEndModal'
// import ProfileHeader from '@/components/fairplay/Profile/ProfileHeader'
import ProfileCard from '@/components/fairplay/Profile/ProfileCard'
// import ProfileMembership from '@/components/sections/ProfileMembership'
// import Button from '@/components/Button'
// import Ribbon from '@/components/fairplay/Ribbon'
// import eventScript from '../../../services/script'
// import socket1 from '@/plugins/newSocket.client'
import service from '@/services/apiService'
import SwitchLocale from '@/components/fairplay/switchLocales'
import encryptor from '~/plugins/encryption'
export default {
  components: {
    UserBalance,
    SessionTimeEndModal,
    // ProfileHeader,
    ProfileCard,
    // ProfileMembership,
    // Button,
    // Ribbon,
    SwitchLocale
  },
  data() {
    return {
      valid: false,
      fairplayAPK: constants.fairplayAPK,
      items: [
        {
          title: 'Betting P&L',
          icon: 'iconpe-profit-loss',
          img: 'bet_pnl.png',
          link: '/profit-loss',
          totalCount: 0
        },
        {
          title: 'My Transactions',
          icon: 'mdi-format-list-bulleted-square',
          img: 'transactions.png',
          link: '/transactions',
          totalCount: 0,
          disable: 'b2b'
        },
        {
          title: 'Profile',
          icon: 'mdi-account',
          img: 'profile.png',
          link: '/profile',
          totalCount: 0
        },
        {
          title: 'My Wallet',
          icon: 'mdi-wallet',
          img: 'wallet.png',
          link: '/wallet',
          totalCount: 0
        },
        {
          title: 'Reset Password',
          icon: 'mdi-lock',
          img: 'reset_password.png',
          link: '/reset-password',
          totalCount: 0
        },
        {
          title: 'Open Bets',
          icon: 'mdi-format-list-bulleted-square',
          img: 'open_bets.png',
          link: '/bets',
          totalCount: 0
        },
        {
          title: 'Favourites',
          icon: 'mdi-star-outline',
          img: 'favorites.png',
          link: '/favorites',
          totalCount: 0
        },
        {
          title: 'Notification',
          icon: 'mdi-bell',
          img: 'notification.png',
          link: '/notifications',
          totalCount: 0
        },
        {
          title: 'Rules & Regulations',
          icon: 'iconpe-rules-regulations',
          img: 'rules.png',
          link: '/rules-regulations',
          totalCount: 0
        },
        {
          title: 'Stake Settings',
          icon: 'mdi-tune',
          img: 'settings.png',
          link: '/settings',
          totalCount: 0
        }
      ],
      // drawerLeft: [
      //   {
      //     title: 'footer.market',
      //     link: '/markets/sportsbook/Cricket',
      //     image: 'market.svg',
      //     alt: 'Market'
      //   },
      //   {
      //     title: 'footer.privacy_policy',
      //     link: '/privacy-policy',
      //     image: 'privacy.svg',
      //     alt: 'Privacy policy'
      //   },
      //   {
      //     title: 'side_menu.t_c',
      //     link: '/termsandconditions',
      //     image: 'terms-and-conditions.svg',
      //     alt: 'Terms&Conditions'
      //   },
      //   {
      //     title: 'Download APK',
      //     link: 'apk',
      //     image: 'arrow-down.png',
      //     alt: 'apk',
      //     disable: 'fps'
      //   }
      // ],
      drawerLeft2: [
        {
          text: 'Market',
          link: '/markets/sportsbook/Cricket',
          alt: 'market',
          img: 'market.png'
        },
        {
          text: 'News',
          link: '/news',
          alt: 'news',
          img: 'news.png'
        },
        {
          text: 'About',
          link: '/about-us',
          alt: 'about',
          img: 'about-us.png'
        },
        // {
        //   text: 'Blogs',
        //   link: '/',
        //   alt: 'blogs',
        //   img: 'blogs.png'
        // },
        {
          text: 'Privacy Policy',
          link: '/privacy-policy',
          alt: 'privacy-policy',
          img: 'privacy.png'
        },
        {
          text: 'FAQ',
          link: '/faqs',
          alt: 'faqs',
          img: 'faqs.png'
        },
        {
          text: 'Promotions',
          link: '/promotions',
          alt: 'promotions',
          img: 'promotions.png'
        },
        {
          text: 'Loyalty',
          link: '/loyalty',
          alt: 'loyalty',
          img: 'loyalty.png'
        },
        {
          text: 'Affiliate',
          link: '/affiliate-partner',
          alt: 'affiliate',
          img: 'affiliate.png'
        },
        {
          text: 'T&C',
          link: '/termsandconditions',
          alt: 'termsandconditions',
          img: 'terms-and-conditions.png'
        }
      ],
      popupTimer: 3000,
      logoutTimer: 3600,
      idleInterval: null,
      idleTime: 0,
      showModalTime: false,
      feedback: '',
      feedbackFormDialog: false,
      feedbackInput: '',
      rules: {
        feedbackInput: [
          (v) => !!v || 'Please enter your feedback',
          (v) =>
            (v || '').length <= 200 ||
            'This field exceeds maximum allowed characters'
        ]
      }
    }
  },

  computed: {
    ...mapState({
      totalNoCount: (state) => state.openBets.totalNoCount
    }),
    getWhatsappData() {
      return this.$store.state.user.whatsappData
    },
    memberCode() {
      return this.$store.state.auth.user.memberCode
    },
    version() {
      return this.$store.state.profile.appversion
    },
    walletDetails() {
      return this.$store.state.wallet.data
    },
    isB2C() {
      return this.$store.state.user.isB2C ? 'b2c' : 'b2b'
    },
    getUserCategory() {
      return this.$store.state.wallet.userCategory
    },
    notificationCount() {
      return this.$store.state.profile.notificationData.count
    },
    drawer: {
      get() {
        return this.$store.getters['drawer/value']
      },
      set(v) {
        return this.$store.commit('drawer/toggle', v)
      }
    },
    drawer1: {
      get() {
        return this.$store.getters['drawer/value1']
      },
      set(v) {
        return this.$store.commit('drawer/toggle1', v)
      }
    },
    name() {
      return this.$store.state.profile.data.name
    },
    user() {
      return this.$store.state.profile.data
    },
    userPendingBonusSum() {
      return this.$store.state.userBalance.userPendingBonusSum
    }
  },
  created() {
    if (this.$auth.loggedIn) {
      this.activeGames()
      if (this.memberCode || localStorage.getItem('memberCode')) {
        this.$store.dispatch(
          'profile/getLoyaltyData',
          this.memberCode || localStorage.getItem('memberCode')
        )
      }
      // console.log('conponent created')
      // this.$store.dispatch('profile/getLoyaltyData', this.memberCode)
    }
    this.startTimer()
  },
  mounted() {
    document.addEventListener('mousemove', this.mousemoveEvent)
    document.addEventListener('click', this.clickEvent)
    document.addEventListener('keypress', this.keypressEvent)
    if (this.$auth && this.$auth.loggedIn) {
      this.callCount()
      const userDetail = JSON.parse(
        encryptor.decrypt(localStorage.getItem('getOneUser'))
      )
      if (userDetail && userDetail.data && userDetail.data.userData) {
        // const userId = userDetail.data.userData._id
        // socket1.on(`userBonus-${userId}`, (data) => {
        //   try {
        //     this.$store.dispatch('userBalance/getCurrentBalance')
        //   } catch (err) {
        //     // console.log('ERROR:', err)
        //   }
        // })
      }
    }
  },
  methods: {
    getWhatsappLogo() {
      return require('@/assets/images/register-whatsapp.png')
    },
    redirectToUrl(link) {
      // router.push
      if (link === 'apk') {
        // this.addUserToDownload()
        document.location = this.fairplayAPK
        return
      }
      this.$router.push(link)
    },
    async callCount() {
      await this.$store.dispatch('openBets/getBetCount')
    },
    goToWhatsapp() {
      // window.open(`https://api.whatsapp.com/send?phone=${constants.CONTACT_NO}`)
      // window.open(
      //   `https://api.whatsapp.com/send?phone=https://web.whatsapp.com/send?phone=${constants.WHATSAPP_NO}`
      // )
      if (
        this.getWhatsappData &&
        this.getWhatsappData.isWhatsAppNo &&
        this.getWhatsappData.whatsAppLink
      ) {
        // window.open(
        //   `https://api.whatsapp.com/send?phone=${this.getWhatsappData.number}`
        // )
        return this.getWhatsappData.whatsAppLink
      }
    },
    showSnackbar({ content, color }) {
      this.$store.commit('snackbar/open', {
        text: content,
        color
      })
    },
    // getJoinNowEvent() {
    //   eventScript.calledJoinNowEventScript()
    // },
    openFeedback() {
      this.feedbackFormDialog = true
      this.drawer = false
    },
    closeFeedbackFormDialog() {
      // this.$refs.surveyForm.resetValidation()
      // this.$refs.surveyForm.reset()
      this.feedbackFormDialog = false
    },
    async submitUserFeedback() {
      this.$refs.feedbackFrom.validate()

      if (!this.$refs.feedbackFrom.validate()) {
        return
      }
      // this.surveyFormDialog = false
      const response = await this.$store.dispatch('feedbackForm/userFeedback', {
        feedback: this.feedbackInput
      })
      if (response && response.status && response.status === 200) {
        this.showSnackbar({
          content: response.data.data
            ? response.data.data
            : 'Feedback Updated Successfully',
          color: 'success'
        })
        this.$refs.feedbackFrom.reset()
        this.$refs.feedbackFrom.resetValidation()
        this.feedbackFormDialog = false
      } else {
        if (
          response &&
          response.data &&
          response.data.error &&
          response.data.error.feedback
        ) {
          this.showSnackbar({
            content: response.data.error.feedback,
            color: 'error'
          })
        } else {
          this.showSnackbar({
            content: response.data.error,
            color: 'error'
          })
        }
        this.$store.commit('feedbackFrom/SET_ERROR', null)
        this.$refs.feedbackFrom.reset()
        this.$refs.feedbackFrom.resetValidation()
        this.feedbackFormDialog = false
      }
    },
    Closedialog() {
      this.$refs.feedbackFrom.resetValidation()
      this.$refs.feedbackFrom.reset()
      this.feedbackFormDialog = false
    },
    openChat() {
      document.getElementById('fc_frame').style.visibility = 'visible'
      document.getElementById('fc_frame').classList.remove('d-none')
      // window.fcWidget.open()
    },
    clickEvent() {
      if (!this.showModalTime) {
        this.idleTime = 0
      }
    },
    mousemoveEvent() {
      if (!this.showModalTime) {
        this.idleTime = 0
      }
    },
    keypressEvent() {
      if (!this.showModalTime) {
        this.idleTime = 0
      }
    },
    startTimer() {
      clearInterval(this.idleInterval)
      this.idleTime = 0
      if (this.$auth.loggedIn) {
        this.idleInterval = setInterval(this.timerIncrement, 1000)
      }
    },

    timerIncrement() {
      this.idleTime = this.idleTime + 1
      // popup function
      if (this.idleTime === this.popupTimer) {
        this.showModalTime = true
      }

      if (this.idleTime >= this.logoutTimer) {
        clearInterval(this.idleInterval)
        this.$store.dispatch('user/logout')
        this.idleTime = 0
      }
    },

    SessionOut(value) {
      this.showModalTime = value
      this.idleTime = 0
    },

    activeGames() {
      if (!this.$auth.loggedIn) {
        this.items = this.items.filter((game) => {
          if (game.title === 'Favorites' || game.title === 'Settings') {
            return !game
          }
          return game
        })
      }
    },
    getCategoryWiseBackgroundImage() {
      if (this.getUserCategory && this.getUserCategory.categoryName) {
        if (this.getUserCategory.categoryName === 'Blue') {
          return 'left-blue-class'
        } else if (this.getUserCategory.categoryName === 'Silver') {
          return 'left-silver-class'
        } else if (this.getUserCategory.categoryName === 'Gold') {
          return 'left-gold-class'
        } else if (this.getUserCategory.categoryName === 'Platinum') {
          return 'left-platinum-class'
        } else {
          return 'left-blue-class'
        }
      } else {
        return 'left-blue-class'
      }
    },
    onRequestcall() {
      if (this.$cookies.isKey('callCookies')) {
        this.showSnackbar({
          content: 'Request already in progress, please try after 5 mins',
          color: 'error'
        })
      } else {
        this.getCall()
      }
    },
    async getCall() {
      try {
        const datasent = {
          number: this.user.mobileNo.includes('+')
            ? +this.user.mobileNo.substring(1)
            : +this.user.mobileNo
        }
        const calldone = await service.apiCallWithHeader(
          '/v2/getWebCall',
          datasent
        )
        if (calldone && calldone.data && calldone.status === 200) {
          this.showSnackbar({
            content: 'Our customer representative will get back to you shortly',
            color: 'success'
          })
        } else if (calldone && calldone.data && calldone.data.error) {
          this.showSnackbar({
            content: calldone.data.error.number,
            color: 'error'
          })
        } else {
          this.showSnackbar({
            content: 'Something went wrong',
            color: 'error'
          })
        }
        this.$cookies.set('callCookies', 'callCookies', '5min')
        this.drawer = false
      } catch (e) {
        this.showSnackbar({
          content: e,
          color: 'error'
        })
      }
    }
  }

  // destroyed() {
  //   clearInterval(this.idleInterval)
  // }
}
</script>

<style lang="scss" scoped>
.mobileLang {
  float: left;
  margin-bottom: 5px;
}
.drawerLeft {
  .v-list-item--active {
    background: $primary;
  }
  .leftheight {
    height: 130px;
  }
  .v-list-item:hover {
    background: $primary;
    .invert___image {
      filter: brightness(0) invert(1) sepia(1) saturate(10000%)
        hue-rotate(180deg);
    }
  }
}
.h220 {
  height: 220px;
}
.left-blue-class {
  background-image: url('~assets/images/loyalty/left-blue.jpg');
}
.left-silver-class {
  background-image: url('~assets/images/loyalty/left-silver.jpg');
}
.left-gold-class {
  background-image: url('~assets/images/loyalty/left-gold.jpg');
}
.left-platinum-class {
  background-image: url('~assets/images/loyalty/left-platinum.jpg');
}
.bg-image-css {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.v-navigation-drawer {
  z-index: 1000;
}
.padding-bottom {
  padding-bottom: 100px !important;
}
.left-border {
  border-left: 0.5px groove #f37415;
}
.invert-image {
  filter: invert(1);
}
.list_border {
  border-top: 1px solid grey;
}
.border-bottom.v-list-item {
  border-bottom: 1px solid gray;
}
.leftheight.border-bottom {
  height: 50px;
  padding: 0;
}
.arrow_width {
  width: 100%;
}
.arrow_width > .icon {
  color: grey;
}
.list___items:last-child > .border-bottom.v-list-item {
  border-bottom: none;
}
.drawerBt {
  padding-bottom: 120px;
  max-width: fit-content;
}
.whatsapp-style > .whatsapp-text {
  border: 1px solid #fff !important;
  box-shadow: none;
  border-radius: 16px;
  width: 100%;
}
</style>
